import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CustomPrompt = ({ isOpen, onClose, onSubmit, message }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  if (!isOpen) return null;

  const predefinedTimes = [5, 10, 15, 20];

  return (
    <div className="custom-prompt-overlay" onClick={onClose}>
      <div className="custom-prompt" onClick={(e) => e.stopPropagation()}>
        <p>{message}</p>
        <div className="predefined-times">
          {predefinedTimes.map(time => (
            <button 
              key={time} 
              onClick={() => onSubmit(time)}
              className="time-button"
            >
              {time} {t('min')}
            </button>
          ))}
        </div>
        <input
          type="number"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={t('otherWaitTime')}
          className="custom-input"
        />
        <button 
          onClick={() => onSubmit(parseInt(value))}
          className="confirm-button"
        >
          {t('confirm')}
        </button>
      </div>
    </div>
  );
};

export default CustomPrompt;

import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import DisneylandPark from './DisneylandPark';
import WaltDisneyStudios from './WaltDisneyStudios';
import ShowsAndEvents from './ShowsAndEvents';
import Restaurants from './Restaurants';
import ErrorBoundary from './components/ErrorBoundary';
import ThemeToggle from './components/ThemeToggle';
import './styles/App.css';
import ParticleButton from './components/ParticleButton';

const client = new ApolloClient({
  uri: 'https://api.dlpwait.com/',
  cache: new InMemoryCache()
});

function App() {
  const { i18n, t } = useTranslation();
  const [error, setError] = useState(null);
  const [waitTimeThresholds, setWaitTimeThresholds] = useState({});
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [isLoading, setIsLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return prefersDark || true;
  });
  const [isLoadingVisible, setIsLoadingVisible] = useState(true);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    document.documentElement.classList.toggle('dark-mode', isDarkMode);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  const setWaitTimeThreshold = (attractionId, threshold) => {
    setWaitTimeThresholds(prev => ({
      ...prev,
      [attractionId]: threshold
    }));
  };

  const handleError = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => setError(null), 5000);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    setIsLanguageMenuOpen(false);
  };

  const updateLoadingState = (loadingState) => {
    if (!loadingState) {
      setIsLoading(false);
      setTimeout(() => setIsLoadingVisible(false), 500);
    } else {
      setIsLoading(true);
      setIsLoadingVisible(true);
    }
  };

  return (
    <ApolloProvider client={client}>
      <ErrorBoundary>
        <Router>
          <div className="container">
            <ThemeToggle isDarkMode={isDarkMode} toggleTheme={toggleTheme} />
            <div className="logo-container">
              <Link to="/">
                <img src="/images/MPTlogo.png" alt="Magic Park Times Logo" className="logo logo-light" />
                <img src="/images/MPTlogo-dark.png" alt="Magic Park Times Logo" className="logo logo-dark" />
              </Link>
            </div>
            
            <nav className="view-selector">
              <ParticleButton as={NavLink} to="/" className="nav-button" activeclassname="active" end>{t('navigation.disneylandPark')}</ParticleButton>
              <ParticleButton as={NavLink} to="/studios" className="nav-button" activeclassname="active">{t('navigation.waltDisneyStudios')}</ParticleButton>
              <ParticleButton as={NavLink} to="/shows" className="nav-button" activeclassname="active">{t('navigation.shows')}</ParticleButton>
              <ParticleButton as={NavLink} to="/restaurants" className="nav-button" activeclassname="active">{t('navigation.restaurants')}</ParticleButton>
            </nav>

            {isLoadingVisible && (
              <div className={`loading-overlay ${isLoading ? 'visible' : 'hidden'}`}>
                <div className="mickey-loading">
                  <div className="ear ear-left"></div>
                  <div className="ear ear-right"></div>
                  <div className="head"></div>
                </div>
              </div>
            )}

            <div className="language-selector">
              <button onClick={() => setIsLanguageMenuOpen(!isLanguageMenuOpen)} aria-label={t('changeLanguage')}>
                <img src={`/images/${currentLanguage}-flag.png`} alt={t(`languages.${currentLanguage}`)} width="28" height="19" />
              </button>
              {isLanguageMenuOpen && (
                <div className="language-dropdown">
                  <button onClick={() => changeLanguage('fr')}>
                    <img src="/images/fr-flag.png" alt={t('languages.fr')} width="28" height="19" /> FR
                  </button>
                  <button onClick={() => changeLanguage('en')}>
                    <img src="/images/en-flag.png" alt={t('languages.en')} width="28" height="19" /> EN
                  </button>
                  <button onClick={() => changeLanguage('es')}>
                    <img src="/images/es-flag.png" alt={t('languages.es')} width="28" height="19" /> ES
                  </button>
                  <button onClick={() => changeLanguage('it')}>
                    <img src="/images/it-flag.png" alt={t('languages.it')} width="28" height="19" /> IT
                  </button>
                  <button onClick={() => changeLanguage('de')}>
                    <img src="/images/de-flag.png" alt={t('languages.de')} width="28" height="19" /> DE
                  </button>
                </div>
              )}
            </div>

            {error && <div className="error-message">{error}</div>}

            <Routes>
              <Route path="/" element={<DisneylandPark onError={handleError} setWaitTimeThreshold={setWaitTimeThreshold} waitTimeThresholds={waitTimeThresholds} updateLoadingState={updateLoadingState} />} />
              <Route path="/studios" element={<WaltDisneyStudios onError={handleError} setWaitTimeThreshold={setWaitTimeThreshold} waitTimeThresholds={waitTimeThresholds} updateLoadingState={updateLoadingState} />} />
              <Route path="/shows" element={<ShowsAndEvents updateLoadingState={updateLoadingState} />} />
              <Route path="/restaurants" element={<Restaurants updateLoadingState={updateLoadingState} />} />
            </Routes>
          </div>
        </Router>
      </ErrorBoundary>
    </ApolloProvider>
  );
}

export default App;

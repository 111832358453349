import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_RESTAURANTS } from './queries/restaurantQueries';

function Restaurants({ updateLoadingState }) {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_RESTAURANTS);
  const [sortBy, setSortBy] = useState(() => {
    return localStorage.getItem('restaurantSortPreference') || 'emplacement';
  });
  const [contentLoaded, setContentLoaded] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    updateLoadingState(loading);
    if (!loading) {
      requestAnimationFrame(() => {
        setContentLoaded(true);
      });
    }
  }, [loading, updateLoadingState]);

  if (loading) return <div>{t('common.loading')}</div>;
  if (error) return <div className="error-message">{t('errors.fetchRestaurants')}</div>;

  const formatTime = (time) => {
    return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const getRestaurantStatus = (openingTime, closingTime) => {
    if (!openingTime || !closingTime) return null;
    
    const now = new Date();
    const opening = new Date(openingTime);
    const closing = new Date(closingTime);
    
    // Calcul des minutes avant la fermeture
    const minutesUntilClosing = Math.floor((closing - now) / (1000 * 60));
    
    if (now >= opening && now <= closing) {
      if (minutesUntilClosing <= 20) {
        return { status: 'closing-soon', text: t('restaurants.closingSoon') };
      }
      return { status: 'open', text: t('restaurants.open') };
    }
    return { status: 'closed', text: t('restaurants.closed') };
  };

  const sortedRestaurants = [...data.restaurants].sort((a, b) => {
    if (sortBy === 'alphabetical') {
      return a.name.localeCompare(b.name);
    } else if (sortBy === 'emplacement') {
      const parkOrder = {
        'Disneyland Park': 1,
        'Walt Disney Studios': 2,
        'Disney Village': 3
      };
      const aParkOrder = parkOrder[a.park.name] || 4;
      const bParkOrder = parkOrder[b.park.name] || 4;
      if (aParkOrder !== bParkOrder) {
        return aParkOrder - bParkOrder;
      }
      return a.park.name.localeCompare(b.park.name) || a.name.localeCompare(b.name);
    }
  });

  const restaurantsByLocation = sortedRestaurants.reduce((acc, restaurant) => {
    if (restaurant.status === 'OPERATING') {
      const location = sortBy === 'alphabetical' ? 'all' : restaurant.park.name;
      if (!acc[location]) {
        acc[location] = [];
      }
      acc[location].push(restaurant);
    }
    return acc;
  }, {});

  // Fonction de réservation temporairement désactivée
  /*
  const buildReservationUrl = (restaurantId) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    
    const languageMap = {
      'fr': 'fr-fr',
      'en': 'en-gb',
      'es': 'es-es',
      'de': 'de-de',
      'it': 'it-it'
    };

    const locale = languageMap[currentLanguage] || 'en-gb';
    return `https://bookrestaurants.disneylandparis.com/${locale}?id=${restaurantId}`;
  };
  */

  const RestaurantItem = ({ restaurant }) => (
    <div key={restaurant.id} className="restaurant-item">
      <div className="restaurant-name">{restaurant.name}</div>
      {restaurant.openingTime && restaurant.closingTime && (
        <>
          <div className="restaurant-hours">
            <span className="hours-range">
              {formatTime(restaurant.openingTime)} - {formatTime(restaurant.closingTime)}
            </span>
            <span className={`status-pill ${getRestaurantStatus(restaurant.openingTime, restaurant.closingTime)?.status}`}>
              {getRestaurantStatus(restaurant.openingTime, restaurant.closingTime)?.text}
            </span>
          </div>
        </>
      )}
      {restaurant.cuisines && restaurant.cuisines.length > 0 && (
        <div className="restaurant-cuisine">{restaurant.cuisines.join(', ')}</div>
      )}
      {sortBy === 'alphabetical' && (
        <div className="restaurant-park">{restaurant.park.name}</div>
      )}
      <div className="restaurant-actions">
        {restaurant.menu?.available && (
          <a 
            href={restaurant.menu.url}
            target="_blank"
            rel="noopener noreferrer"
            className="restaurant-button menu-button"
          >
            <svg 
              width="16" 
              height="16" 
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                d="M3 6h18M3 12h18M3 18h18" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round"
              />
            </svg>
            {t('restaurants.menu')}
          </a>
        )}
        {/* Bouton de réservation temporairement désactivé
        {restaurant.mobileReservation?.available && (
          <a 
            href={buildReservationUrl(restaurant.id)}
            target="_blank"
            rel="noopener noreferrer"
            className="restaurant-button reservation-button"
          >
            <svg 
              width="16" 
              height="16" 
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
              <path 
                d="M16 2V6M8 2V6M3 10H21" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
            </svg>
            {t('restaurants.reserve')}
          </a>
        )}
        */}
        {restaurant.mobileOrder?.available && (
          <a 
            href={restaurant.mobileOrder.url}
            target="_blank"
            rel="noopener noreferrer"
            className="restaurant-button mobile-order-button"
          >
            <svg 
              className="mobile-order-icon" 
              width="16" 
              height="16" 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
            >
              <path 
                d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
              <path 
                d="M12 18H12.01" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
              />
            </svg>
            {t('restaurants.mobileOrder')}
          </a>
        )}
      </div>
    </div>
  );

  return (
    <div className={`restaurants-content ${contentLoaded ? 'loaded' : ''}`}>
      {!contentLoaded && (
        <div className="loading-overlay">
          <div className="mickey-loading">
            <div className="ear ear-left"></div>
            <div className="ear ear-right"></div>
            <div className="head"></div>
          </div>
        </div>
      )}
      {contentLoaded && (
        <>
          <div className="park-header">
            <h2 className="park-title">{t('restaurants.title')}</h2>
          </div>
          <div className="filters">
            <div className="filters-right">
              <div className="sort-dropdown">
                <button onClick={() => setIsDropdownOpen(!isDropdownOpen)} className="sort-dropdown-button">
                  {sortBy === 'alphabetical' ? t('sortBy.name') : t('sortBy.emplacement')}
                  <span className={`arrow ${isDropdownOpen ? 'up' : ''}`}>▼</span>
                </button>
                {isDropdownOpen && (
                  <div className="sort-dropdown-menu">
                    <button onClick={() => { setSortBy('alphabetical'); setIsDropdownOpen(false); }}>{t('sortBy.name')}</button>
                    <button onClick={() => { setSortBy('emplacement'); setIsDropdownOpen(false); }}>{t('sortBy.emplacement')}</button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {Object.entries(restaurantsByLocation).map(([location, restaurants]) => (
            <div key={location} className="location-group">
              {sortBy === 'emplacement' && <h3 className="location-title">{location}</h3>}
              <div className="restaurants-list">
                {restaurants.map((restaurant) => (
                  <RestaurantItem key={restaurant.id} restaurant={restaurant} />
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default Restaurants;

import { gql } from '@apollo/client';

export const GET_ENTERTAINMENT = gql`
  query GetEntertainment {
    entertainment {
      id
      name
      active
      category
      schedules {
        startTime
        endTime
        date
        language
      }
      park {
        name
      }
      region
    }
  }
`;

import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { GET_ENTERTAINMENT } from './queries/showQueries';
import { closureDates } from './components/ParkContent';

const formatLanguage = (language) => {
  switch(language?.toLowerCase()) {
    case 'fr':
      return <img src="/images/fr-flag.png" alt="FR" className="language-flag" />;
    case 'en':
      return <img src="/images/en-flag.png" alt="EN" className="language-flag" />;
    default:
      return language;
  }
};

const ShowsAndEvents = ({ updateLoadingState }) => {
  const { t, i18n } = useTranslation();
  const { loading, error, data } = useQuery(GET_ENTERTAINMENT);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [expandedShow, setExpandedShow] = useState(null);

  const categories = [
    { id: 'all', name: t('common.allCategories') },
    { id: 'Character Experience - Meet & Greet', name: t('categories.character') },
    { id: 'Stage Show', name: t('categories.show') },
    { id: 'Parade', name: t('categories.parade') },
    { id: 'Fireworks', name: t('categories.fireworks') },
    { id: 'Atmosphere', name: t('categories.atmosphere') },
    { id: 'Concert', name: t('categories.concert') },
    { id: 'Magic Shots', name: t('categories.magicShots') }
  ];

  const permanentShows = [
    {
      id: 'together-pixar',
      name: 'Together: A Pixar Musical Adventure',
      category: 'Stage Show',
      active: true,
      park: { name: 'Walt Disney Studios Park' }
    },
    {
      id: 'frozen-invitation',
      name: 'Frozen: A Musical Invitation',
      category: 'Stage Show',
      active: true,
      park: { name: 'Walt Disney Studios Park' }
    },
    {
      id: 'mickey-magician',
      name: 'Mickey and the Magician',
      category: 'Stage Show',
      active: true,
      park: { name: 'Walt Disney Studios Park' }
    }
  ];

  const allShows = useMemo(() => {
    const today = new Date().toISOString().split('T')[0];
    
    // Filtrer les spectacles de l'API
    const apiShows = data?.entertainment?.filter(show => {
      const isActive = show.active;
      const hasScheduleToday = show.schedules?.some(schedule => 
        schedule.date === today
      );
      return isActive && hasScheduleToday;
    }) || [];

    // Ajouter les spectacles permanents en vérifiant les dates de fermeture
    const permanentWithStatus = permanentShows.map(show => {
      const closure = closureDates[show.name];
      const isClosed = closure && (!closure.end || new Date(closure.end) >= new Date());
      
      return {
        ...show,
        active: !isClosed
      };
    });

    return [...apiShows, ...permanentWithStatus];
  }, [data?.entertainment]);

  // Modifier la référence à todayShows pour utiliser allShows
  const filteredShows = allShows.filter(show =>
    selectedCategory === 'all' || show.category === selectedCategory
  );

  useEffect(() => {
    if (!loading) {
      setContentLoaded(true);
      updateLoadingState && updateLoadingState(false);
    }
  }, [loading, updateLoadingState]);

  const formatTime = (timeString) => {
    if (!timeString) return t('common.notAvailable');
    try {
      const [hours, minutes] = timeString.split(':');
      const date = new Date();
      date.setHours(parseInt(hours, 10));
      date.setMinutes(parseInt(minutes, 10));
      return new Intl.DateTimeFormat(i18n.language, { 
        hour: '2-digit', 
        minute: '2-digit',
        hour12: false 
      }).format(date);
    } catch (error) {
      console.error('Erreur lors du formatage de l\'heure:', error);
      return t('common.notAvailable');
    }
  };

  const formatShowName = (name) => {
    if (name.toLowerCase().includes('reserved viewing area')) {
      return null;
    }

    return name
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/(\w)(Dark)/g, '$1 Dark')
      .replace(/(\w)(Selfie)/g, '$1 Selfie')
      .replace(/(\w)(Side)/g, '$1 Side')
      .replace(/(\w)(Spot)/g, '$1 Spot')
      .replace(/(\w)(Show)/g, '$1 Show')
      .replace(/(\w)(Meet)/g, '$1 Meet')
      .replace(/(\w)(Greet)/g, '$1 Greet');
  };

  const formatLanguage = (language) => {
    switch(language?.toLowerCase()) {
      case 'fr':
        return <img src="/images/fr-flag.png" alt="FR" className="language-flag" />;
      case 'en':
        return <img src="/images/en-flag.png" alt="EN" className="language-flag" />;
      default:
        return language;
    }
  };

  const formatClosureDate = (dateString) => {
    if (!dateString) return t('closureEndUnknown');
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(i18n.language, options);
  };

  const renderShowItem = (show) => {
    const formattedName = formatShowName(show.name);
    if (!formattedName) return null;

    const closure = closureDates[show.name];
    const isClosed = closure && (!closure.end || new Date(closure.end) >= new Date());
    const canExpand = !!closure;
    
    return (
      <div 
        key={show.id} 
        className={`show-item ${isClosed ? 'attraction-closed' : ''} ${canExpand && expandedShow === show.id ? 'expanded' : ''}`}
        onClick={() => canExpand ? toggleShowDetails(show.id) : null}
      >
        <div className="show-content">
          <div className="show-header">
            <span className="show-name">{formattedName}</span>
            {canExpand && (
              <span className="closure-icon" title={t('closureInfo')}></span>
            )}
          </div>
          {show.schedules && show.schedules.length > 0 && (
            <div className="show-times-wrapper">
              <div className="show-times">
                {show.schedules
                  .filter(schedule => schedule.date === new Date().toISOString().split('T')[0])
                  .map((schedule, index) => (
                    <span key={index} className="show-time">
                      {formatTime(schedule.startTime)}
                      {schedule.language && 
                        <span className="show-language">
                          {formatLanguage(schedule.language)}
                        </span>
                      }
                    </span>
                  ))}
              </div>
            </div>
          )}
        </div>
        {canExpand && expandedShow === show.id && (
          <div className="closure-info">
            <p>{t('closureInfo')}</p>
            <p>{t('closureStart')}: {formatClosureDate(closure.start)}</p>
            <p>{t('closureEnd')}: {closure.end ? formatClosureDate(closure.end) : t('closureEndUnknown')}</p>
          </div>
        )}
      </div>
    );
  };

  const toggleShowDetails = (id) => {
    setExpandedShow(prev => prev === id ? null : id);
  };

  if (loading) {
    return (
      <div className="loading-overlay">
        <div className="mickey-loading">
          <div className="ear ear-left"></div>
          <div className="ear ear-right"></div>
          <div className="head"></div>
        </div>
      </div>
    );
  }

  if (error) return <div className="error-message">{t('errors.fetchShows')}</div>;

  const parks = [
    { name: t('parks.disneylandPark'), apiName: 'Disneyland Park' },
    { name: t('parks.waltDisneyStudios'), apiName: 'Walt Disney Studios Park' },
    { name: t('parks.disneyVillage'), apiName: 'Disney Village' }
  ];

  return (
    <div className={`park-content ${contentLoaded ? 'loaded' : ''}`}>
      <div className="park-header">
        <h2 className="park-title">{t('shows.title')}</h2>
        <div className="sort-dropdown">
          <select 
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="sort-dropdown-button"
          >
            {categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {parks.map(park => {
        const parkShows = filteredShows.filter(show => 
          show.park?.name === park.apiName &&
          (selectedCategory === 'all' || show.category === selectedCategory)
        );

        if (parkShows.length === 0) return null;

        return (
          <div key={park.apiName} className="land-section">
            <h3 className="land-title">{park.name}</h3>
            <div className="shows-list">
              {parkShows.map((show) => renderShowItem(show))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ShowsAndEvents;

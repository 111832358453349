import React, { useEffect, useCallback, useMemo, useState } from 'react';
import useParkData from './hooks/useParkData';
import useSharedParkData from './hooks/useSharedParkData';
import ParkContent from './components/ParkContent';
import { useTranslation } from 'react-i18next';
import { isMobile } from './utils/deviceDetection';

const API_URL = 'https://api.themeparks.wiki/v1/entity/ca888437-ebb4-4d50-aed2-d227f7096968/live';
const SCHEDULE_API_URL = 'https://api.themeparks.wiki/v1/entity/ca888437-ebb4-4d50-aed2-d227f7096968/schedule';
const CACHE_KEY = 'waltDisneyStudiosWaitTimes';
const CALENDAR_URL = 'https://themeparks.wiki/browse/ca888437-ebb4-4d50-aed2-d227f7096968/calendar';

const lands = {
  'Marvel Avengers Campus': ['Avengers Assemble Flight Force', 'Spider-Man W.E.B. Adventure'],
  'Toon Studio': [
    'Art of Disney Animation',
    'Cars Quatre Roues Rallye',
    'Cars ROAD TRIP',
    'Crushs Coaster',
    'Les Tapis Volants - Flying Carpets Over Agrabah',
    'Ratatouille The Adventure',
    'RC Racer',
    'Slinky Dog Zigzag Spin',
    'Toy Soldiers Parachute Drop'
  ],
  'Production Courtyard': ['Meet Spider Man', 'The Twilight Zone Tower of Terror']
};

const PARK_ID = 'WDS';

const WaltDisneyStudios = ({ onError, waitTimeThresholds, updateLoadingState }) => {
  const { waitTimes, lastUpdate, loading, openingHours, debouncedFetchWaitTimes } = useParkData(API_URL, SCHEDULE_API_URL, CACHE_KEY, CALENDAR_URL);
  const { favorites, toggleFavorite, setWaitTimeThreshold } = useSharedParkData();
  const [contentLoaded, setContentLoaded] = useState(false);
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState('all');
  const [sortBy, setSortBy] = useState('waitTime');

  // Ajoutez cette fonction pour filtrer les favoris du DLP
  const getDLPFavorites = useCallback(() => {
    const favoritesInfo = JSON.parse(localStorage.getItem('favoritesInfo') || '{}');
    const dlpLands = {
      'Adventureland': ['Indiana Jones et le Temple du Péril', 'La Cabane des Robinson', 'Le Passage Enchanté d\'Aladdin', 'Pirates of the Caribbean'],
      'Discoveryland': ['Autopia', 'Buzz Lightyear Laser Blast', 'Les Mystères du Nautilus', 'Orbitron', 'Star Tours: L\'Aventure Continue', 'Star Wars Hyperspace Mountain'],
      'Fantasyland': ['Alice\'s Curious Labyrinth', 'Blanche-Neige et les Sept Nains', 'Casey Jr. - Le Petit Train du Cirque', 'Dumbo the Flying Elephant', 'It\'s a Small World', 'Le Carrousel de Lancelot', 'Les Voyages de Pinocchio', 'Mad Hatter\'s Tea Cups', 'Meet Mickey Mouse', 'Peter Pan\'s Flight', 'Princess Pavilion'],
      'Frontierland': ['Big Thunder Mountain', 'Phantom Manor', 'Thunder Mesa Riverboat Landing'],
      'Main Street, U.S.A.': ['Main Street Vehicles']
    };

    const getDLPLand = (attractionName) => {
      const normalizedName = attractionName.toLowerCase().replace(/[®™']/g, '').trim();
      for (const [land, attractions] of Object.entries(dlpLands)) {
        for (const attr of attractions) {
          const normalizedAttr = attr.toLowerCase().replace(/[®™']/g, '').trim();
          if (normalizedName === normalizedAttr || 
              normalizedName.includes(normalizedAttr) || 
              normalizedName.includes(normalizedAttr) ||
              normalizedName.replace(/\s/g, '') === normalizedAttr.replace(/\s/g, '') ||
              normalizedName.replace(/[^\w\s]/g, '') === normalizedAttr.replace(/[^\w\s]/g, '')) {
            return land;
          }
        }
      }
      return 'Autre';
    };

    return favorites
      .filter(fav => fav.startsWith('DLP_'))
      .map(fav => ({
        id: fav.split('_')[1],
        name: favoritesInfo[fav]?.name || 'Attraction inconnue',
        parkId: 'DLP',
        status: 'CLOSED',
        waitTime: -1,
        land: getDLPLand(favoritesInfo[fav]?.name || '')
      }));
  }, [favorites]);

  const getStatusText = (attraction) => {
    switch (attraction.status) {
      case 'CLOSED': return t('closed');
      case 'DOWN': return t('outOfOrder');
      case 'REFURBISHMENT': return t('refurbishment');
      default:
        if (attraction.waitTime === -1) return t('noInfo');
        return `${attraction.waitTime} ${t('min')}`;
    }
  };

  const getStatusClass = (attraction) => {
    switch (attraction.status) {
      case 'CLOSED': return 'closed';
      case 'DOWN': return 'out-of-order';
      case 'REFURBISHMENT': return 'refurbishment';
      default:
        if (attraction.waitTime <= 5) return 'very-short-wait';
        if (attraction.waitTime <= 15) return 'short-wait';
        if (attraction.waitTime <= 30) return 'medium-wait';
        return 'long-wait';
    }
  };

  const getDownTimeDuration = (id) => {
    const downTimeStart = localStorage.getItem(`downTime_${id}`);
    if (!downTimeStart) return t('notAvailable');
    const duration = Math.floor((new Date() - new Date(downTimeStart)) / 60000);
    return t('downTimeDuration', { minutes: duration });
  };

  const formatTime = (dateString) => {
    if (!dateString) return t('notAvailable');
    const date = new Date(dateString);
    return date.toLocaleTimeString(i18n.language, { hour: '2-digit', minute: '2-digit' });
  };

  const getAttractionLand = useCallback((attractionName) => {
    const normalizedName = attractionName.toLowerCase().replace(/[^a-z0-9\s]/g, '').trim();
    for (const [land, attractions] of Object.entries(lands)) {
      for (const attr of attractions) {
        const normalizedAttr = attr.toLowerCase().replace(/[^a-z0-9\s]/g, '').trim();
        if (normalizedName.includes(normalizedAttr)) {
          return land;
        }
      }
    }
    console.warn(`Land non trouvé pour l'attraction: ${attractionName}`);
    return 'Autre';
  }, []);

  const attractionsWithLand = useMemo(() => {
    const normalAttractions = waitTimes.map(attraction => ({
      ...attraction,
      land: getAttractionLand(attraction.name),
      parkId: PARK_ID
    }));

    if (filter === 'favorites') {
      const dlpFavorites = getDLPFavorites();
      const wdsFavorites = normalAttractions.filter(att => favorites.includes(`${PARK_ID}_${att.id}`));
      return [...wdsFavorites, ...dlpFavorites];
    }
    
    return normalAttractions;
  }, [waitTimes, getAttractionLand, getDLPFavorites, filter, favorites, PARK_ID]);

  useEffect(() => {
    updateLoadingState(loading);
    if (!loading) {
      const timer = setTimeout(() => {
        setContentLoaded(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setContentLoaded(false);
    }
  }, [loading, updateLoadingState]);

  return (
    <ParkContent
      parkName="Walt Disney Studios"
      parkId={PARK_ID}
      openingHours={openingHours}
      lastUpdate={lastUpdate}
      loading={loading}
      debouncedFetchWaitTimes={debouncedFetchWaitTimes}
      attractions={attractionsWithLand}
      favorites={favorites}
      toggleFavorite={toggleFavorite}
      setWaitTimeThreshold={setWaitTimeThreshold}
      getStatusClass={getStatusClass}
      getStatusText={getStatusText}
      formatTime={formatTime}
      lands={lands}
      waitTimeThresholds={waitTimeThresholds}
      filter={filter}
      setFilter={setFilter}
      sortBy={sortBy}
      setSortBy={setSortBy}
    />
  );
};

export default WaltDisneyStudios;

import React, { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const ParticleButton = ({ children, className, as: Component = 'button', ...props }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const button = buttonRef.current;
    if (!button) return;

    const createSparkle = ($parent) => {
      const $canvas = document.createElement('canvas');
      $canvas.className = "sparkle-canvas";
      $canvas.style.position = "absolute";
      $canvas.style.top = "-10px";
      $canvas.style.left = "-10px";
      $canvas.style.pointerEvents = "none";
      $parent.appendChild($canvas);

      const context = $canvas.getContext("2d");
      $canvas.width = $parent.offsetWidth + 20;
      $canvas.height = $parent.offsetHeight + 20;

      const sprite = new Image();
      sprite.src = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAHCAYAAAD5wDa1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIE1hY2ludG9zaCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozNDNFMzM5REEyMkUxMUUzOEE3NEI3Q0U1QUIzMTc4NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDozNDNFMzM5RUEyMkUxMUUzOEE3NEI3Q0U1QUIzMTc4NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjM0M0UzMzlCQTIyRTExRTM4QTc0QjdDRTVBQjMxNzg2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjM0M0UzMzlDQTIyRTExRTM4QTc0QjdDRTVBQjMxNzg2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+jzOsUQAAANhJREFUeNqsks0KhCAUhW/Sz6pFSc1AD9HL+OBFbdsVOKWLajH9EE7GFBEjOMxcUNHD8dxPBCEE/DKyLGMqraoqcd4j0ChpUmlBEGCFRBzH2dbj5JycJAn90CEpy1J2SK4apVSM4yiKonhePYwxMU2TaJrm8BpykpWmKQ3D8FbX9SOO4/tOhDEG0zRhGAZo2xaiKDLyPGeSyPM8sCxr868+WC/mvu9j13XBtm1ACME8z7AsC/R9r0fGOf+arOu6jUwS7l6tT/B+xo+aDFRo5BykHfav3/gSYAAtIdQ1IT0puAAAAABJRU5ErkJggg==";

      const createSparkles = (canvasWidth, canvasHeight) => {
        const particles = [];
        const particleCount = 50; // Augmenter le nombre de particules
        for (let i = 0; i < particleCount; i++) {
          particles.push({
            position: {
              x: Math.floor(Math.random() * canvasWidth),
              y: Math.floor(Math.random() * canvasHeight)
            },
            style: Math.floor(Math.random() * 4) * 7,
            delta: {
              x: Math.floor(Math.random() * 1000) - 500,
              y: Math.floor(Math.random() * 1000) - 500
            },
            size: parseFloat((Math.random() * 2).toFixed(2)),
            color: "rgba(255,255,255," + (Math.random() * 0.5 + 0.5) + ")",
            opacity: Math.random()
          });
        }
        return particles;
      };

      const particles = createSparkles($canvas.width, $canvas.height);

      const draw = () => {
        context.clearRect(0, 0, $canvas.width, $canvas.height);

        for (let i = 0; i < particles.length; i++) {
          const particle = particles[i];
          context.save();
          context.globalAlpha = particle.opacity;
          context.drawImage(sprite, particle.style, 0, 7, 7, particle.position.x, particle.position.y, 7, 7);
          
          context.globalCompositeOperation = "source-atop";
          context.globalAlpha = 0.5;
          context.fillStyle = particle.color;
          context.fillRect(particle.position.x, particle.position.y, 7, 7);
          
          context.restore();
        }
      };

      const update = () => {
        for (let i = 0; i < particles.length; i++) {
          const particle = particles[i];
          
          const randX = (Math.random() > Math.random() * 2);
          const randY = (Math.random() > Math.random() * 3);
          
          if (randX) {
            particle.position.x += ((particle.delta.x * 0.5) / 1500); 
          }        
          
          if (!randY) {
            particle.position.y -= ((particle.delta.y * 0.5) / 800);
          }

          if (particle.position.x > $canvas.width) {
            particle.position.x = -7;
          } else if (particle.position.x < -7) {
            particle.position.x = $canvas.width; 
          }

          if (particle.position.y > $canvas.height) {
            particle.position.y = -7;
            particle.position.x = Math.floor(Math.random() * $canvas.width);
          } else if (particle.position.y < -7) {
            particle.position.y = $canvas.height; 
            particle.position.x = Math.floor(Math.random() * $canvas.width);
          }
          
          particle.opacity -= 0.005;
          if (particle.opacity <= 0) {
            particle.opacity = 1;
          }
        }
      };

      let animationFrame;
      const animate = () => {
        draw();
        update();
        animationFrame = requestAnimationFrame(animate);
      };

      animate();

      return () => {
        cancelAnimationFrame(animationFrame);
        $parent.removeChild($canvas);
      };
    };

    let cleanup;
    const handleMouseEnter = () => {
      cleanup = createSparkle(button);
    };

    const handleMouseLeave = () => {
      if (cleanup) {
        cleanup();
        cleanup = null;
      }
    };

    button.addEventListener('mouseenter', handleMouseEnter);
    button.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      button.removeEventListener('mouseenter', handleMouseEnter);
      button.removeEventListener('mouseleave', handleMouseLeave);
      if (cleanup) {
        cleanup();
      }
    };
  }, []);

  if (Component === NavLink) {
    return (
      <NavLink ref={buttonRef} className={`sparkley nav-button ${className}`} {...props}>
        {children}
      </NavLink>
    );
  }

  return (
    <Component ref={buttonRef} className={`sparkley nav-button ${className}`} {...props}>
      {children}
    </Component>
  );
};

export default ParticleButton;
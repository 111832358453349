import { gql } from '@apollo/client';

export const GET_RESTAURANTS = gql`
  query GetRestaurants($search: String) {
    restaurants(search: $search) {
      id
      name
      status
      category
      cuisines
      openingTime
      closingTime
      mobileOrder {
        available
        url
      }
      menu {
        available
        url
      }
      mobileReservation {
        available
        url
      }
      park {
        name
      }
    }
  }
`;


import React, { useState, useRef, useCallback, useEffect } from 'react';
import { RefreshCw, Clock, Star, Bell, ChevronDown } from 'lucide-react';
import anime from 'animejs';
import CustomPrompt from './CustomPrompt';
import { FixedSizeList as List } from 'react-window';
import { useTranslation } from 'react-i18next';
import { isMobile } from '../utils/deviceDetection';

const closureDates = {
  "Pirates' Beach": { start: "2024-09-01", end: null },
  "Blanche-Neige et les Sept Nains®": { start: "2024-11-04", end: "2024-11-16" },
  "Princess Pavilion": { start: "2024-11-18", end: "2024-11-23" },
  "Phantom Manor": { start: "2024-11-18", end: "2024-11-30" },
  "Together: A Pixar Musical Adventure": { start: "2024-11-04", end: "2024-12-14" },
  "Frozen: A Musical Invitation": { start: "2024-11-04", end: "2025-02-15" },
  "Mickey and the Magician": { start: "2025-01-06", end: "2025-04-21" },
  "Big Thunder Mountain": { start: "2025-01-06", end: null },
  "Le Pays des Contes de Fées": { start: "2025-01-06", end: "2025-01-11" },
  "Casey Jr. – le Petit Train du Cirque": { start: "2025-01-06", end: "2025-01-11" },
  "Le Passage Enchanté d'Aladdin": { start: "2025-01-06", end: "2025-01-18" },
  "it's a small world": { start: "2025-01-20", end: null }
};

const findClosureInfo = (attractionName) => {
  const normalize = (name) => name.toLowerCase().replace(/[®™]/g, '').replace(/['"]/g, '').trim();
  const normalizedName = normalize(attractionName);
  
  for (const [key, value] of Object.entries(closureDates)) {
    if (normalize(key) === normalizedName) {
      return value;
    }
  }
  return null;
};

const normalizeName = (name) => {
  return name.toLowerCase()
    .replace(/[®™]/g, '')
    .replace(/[']/g, '')
    .replace(/\s+/g, ' ')
    .trim();
};

const ParkContent = ({ 
  parkName, 
  parkId,
  openingHours, 
  lastUpdate, 
  loading,
  debouncedFetchWaitTimes,
  attractions,
  favorites,
  toggleFavorite,
  getStatusClass,
  getStatusText,
  formatTime,
  lands,
}) => {
  const [filter, setFilter] = useState('all');
  const [sortBy, setSortBy] = useState(() => {
    return localStorage.getItem('attractionSortPreference') || 'waitTime';
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const starRefs = useRef({});
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const [currentAttraction, setCurrentAttraction] = useState(null);
  const [promptPosition, setPromptPosition] = useState({ top: 0, left: 0 });
  const { t, i18n } = useTranslation();
  const [contentLoaded, setContentLoaded] = useState(false);
  const [expandedAttraction, setExpandedAttraction] = useState(null);

  useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setContentLoaded(true);
      }, 500);
      return () => clearTimeout(timer);
    } else {
      setContentLoaded(false);
    }
  }, [loading]);

  const isAttractionFavorite = useCallback((attractionId, attractionParkId) => {
    const favoriteKey = `${attractionParkId || parkId}_${attractionId}`;
    return favorites.includes(favoriteKey);
  }, [favorites, parkId]);

  const filteredAttractions = attractions.filter(attraction => {
    if (filter === 'favorites') return isAttractionFavorite(attraction.id, attraction.parkId);
    return true;
  });

  const sortedAttractions = [...filteredAttractions].sort((a, b) => {
    if (sortBy === 'name') return a.name.localeCompare(b.name);
    if (sortBy === 'waitTime') return b.waitTime - a.waitTime;
    return 0;
  });


  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const getSortByText = () => {
    switch(sortBy) {
      case 'name': return t('sortBy.name');
      case 'waitTime': return t('sortBy.waitTime');
      case 'land': return t('sortBy.land');
      default: return t('sortBy.waitTime');
    }
  };

  const animateStar = (id) => {
    anime({
      targets: starRefs.current[id],
      scale: [1, 1.5, 1],
      rotate: '1turn',
      duration: 1000,
      easing: 'easeInOutQuad'
    });
  };

  const toggleAttractionDetails = (id) => {
    setExpandedAttraction(prev => (prev === id ? null : id));
  };

  const formatClosureDate = (dateString) => {
    if (!dateString) return t('closureEndUnknown');
    const date = new Date(dateString);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(i18n.language, options);
  };

  const handleToggleFavorite = (attractionId, attractionName, attractionParkId) => {
    toggleFavorite(attractionId, attractionName, attractionParkId || parkId);
    animateStar(attractionId);
  };

  const renderAttractionItem = useCallback((attraction) => {
    const closure = findClosureInfo(attraction.name);
    const isClosed = closure && (!closure.end || new Date(closure.end) >= new Date());
    const canExpand = !!closure;

    return (
      <div 
        key={attraction.id} 
        className={`attraction-item ${isClosed ? 'attraction-closed' : ''} ${canExpand && expandedAttraction === attraction.id ? 'expanded' : ''}`} 
        onClick={() => canExpand ? toggleAttractionDetails(attraction.id) : null}
      >
        <span className="attraction-name">{attraction.name}</span>
        <div className="attraction-right">
          <div className="icons">
            {isClosed && (
              <span className="closure-icon" title={t('closureInfo')}></span>
            )}
            <button 
              onClick={(e) => {
                e.stopPropagation();
                handleToggleFavorite(attraction.id, attraction.name, attraction.parkId);
              }} 
              aria-label={isAttractionFavorite(attraction.id, attraction.parkId) ? t('removeFavorite') : t('addFavorite')}
              aria-pressed={isAttractionFavorite(attraction.id, attraction.parkId)}
            >
              <Star 
                ref={el => starRefs.current[attraction.id] = el}
                className={`favorite-icon ${isAttractionFavorite(attraction.id, attraction.parkId) ? 'active' : ''}`}
                fill={isAttractionFavorite(attraction.id, attraction.parkId) ? 'currentColor' : 'none'}
                stroke={isAttractionFavorite(attraction.id, attraction.parkId) ? 'currentColor' : 'currentColor'}
              />
            </button>
          </div>
          <div className="wait-times">
            <div className="wait-time-container">
              <span 
                className={`wait-time ${getStatusClass(attraction)}`}
                aria-label={`${t('waitTime')} ${attraction.name}: ${getStatusText(attraction)}`}
              >
                {attraction.waitTime === -1 
                  ? (attraction.status === 'CLOSED' ? t('closed') : t('outOfOrder'))
                  : `${attraction.waitTime} ${t('min')}`
                }
              </span>
            </div>
            {attraction.singleRiderWaitTime !== -1 && (
              <div className="wait-time-container">
                <span 
                  className={`wait-time ${getStatusClass({ ...attraction, waitTime: attraction.singleRiderWaitTime })}`}
                  aria-label={`${t('singleRider')} ${attraction.name}: ${attraction.singleRiderWaitTime} ${t('min')}`}
                >
                  {`Single: ${attraction.singleRiderWaitTime} ${t('min')}`}
                </span>
              </div>
            )}
          </div>
        </div>
        {canExpand && expandedAttraction === attraction.id && (
          <div className="closure-info">
            <p>{t('closureInfo')}</p>
            <p>{t('closureStart')}: {formatClosureDate(closure.start)}</p>
            <p>{t('closureEnd')}: {closure.end ? formatClosureDate(closure.end) : t('closureEndUnknown')}</p>
          </div>
        )}
      </div>
    );
  }, [favorites, toggleFavorite, parkId, t, expandedAttraction, handleToggleFavorite, isAttractionFavorite]);

  const renderAttractions = () => {
    if (sortBy === 'land') {
      const attractionsByLand = sortedAttractions.reduce((acc, attraction) => {
        if (!acc[attraction.land]) {
          acc[attraction.land] = [];
        }
        acc[attraction.land].push(attraction);
        return acc;
      }, {});

      return Object.entries(attractionsByLand).map(([land, landAttractions]) => (
        <div key={land} className="land-group">
          <h3 className="land-title">{land}</h3>
          {landAttractions.map(attraction => renderAttractionItem(attraction))}
        </div>
      ));
    } else {
      return sortedAttractions.map(attraction => renderAttractionItem(attraction));
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(i18n.language, options);
  };

  const renderFilterButtons = () => (
    <div className="filter-buttons">
      <button
        className={`filter-button ${filter === 'all' ? 'active' : ''}`}
        onClick={() => setFilter('all')}
      >
        {t('filters.all')}
      </button>
      <button
        className={`filter-button ${filter === 'favorites' ? 'active' : ''}`}
        onClick={() => setFilter('favorites')}
      >
        {t('filters.favorites')}
      </button>
    </div>
  );

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    localStorage.setItem('attractionSortPreference', newSortBy);
    toggleDropdown();
  };

  return (
    <div className={`park-content ${contentLoaded ? 'loaded' : ''}`}>
      {!contentLoaded && (
        <div className="loading-overlay">
          <div className="mickey-loading">
            <div className="ear ear-left"></div>
            <div className="ear ear-right"></div>
            <div className="head"></div>
          </div>
        </div>
      )}
      {contentLoaded && (
        <>
          <div className="park-header">
            <h2 className="park-title">{parkName}</h2>
            <div className="opening-hours">
              {openingHours.error ? (
                <p className="error-message">{t('openingHours.error')}</p>
              ) : (
                <>
                  {openingHours.isFuture && (
                    <p className="future-date-notice">
                      {t('openingHours.futureDate', { date: formatDate(openingHours.date) })}
                    </p>
                  )}
                  <p>
                    {t('openingHours.hours', { 
                      openingTime: formatTime(openingHours.openingTime), 
                      closingTime: formatTime(openingHours.closingTime) 
                    })}
                  </p>
                </>
              )}
            </div>
          </div>
          
          <div className="filters">
            <div className="filters-left">
              {renderFilterButtons()}
              <div className="last-update">
                <span className="icon">🕓</span>
                {t('common.lastUpdate', { time: formatTime(lastUpdate) })}
              </div>
            </div>
            <div className="filters-right">
              <div className="sort-dropdown">
                <button onClick={toggleDropdown} className="sort-dropdown-button">
                  {t(`sortBy.${sortBy}`)}
                  <ChevronDown className={`arrow ${isDropdownOpen ? 'up' : ''}`} />
                </button>
                {isDropdownOpen && (
                  <div className="sort-dropdown-menu">
                    <button onClick={() => handleSortChange('name')}>{t('sortBy.name')}</button>
                    <button onClick={() => handleSortChange('waitTime')}>{t('sortBy.waitTime')}</button>
                    {lands && <button onClick={() => handleSortChange('land')}>{t('sortBy.land')}</button>}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="attraction-list">
            <div className="attractions">
              {renderAttractions()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { closureDates };

export default ParkContent;
